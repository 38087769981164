import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import { Detail } from './components/Detail';
import SubscribePage from './components/SubscribePage';
import PaymentPage from './components/PaymentPage';
import LoginPage from './components/LoginPage';
import ScrollToTop from './components/ScrollToTop';
import VideoPage from './components/VideoPage';
import ContactPage from './components/ContactPage';

export default function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<VideoPage landingPage={true} />} />
        <Route path="*" element={<VideoPage landingPage={true} />} />
        <Route path="/" element={<Layout />}>
          <Route path="/videos/" element={<VideoPage />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/subscribe/" element={<SubscribePage />} />
          <Route path="/secure/:id" element={<PaymentPage />} />
          <Route path="/login/" element={<LoginPage />} />
          <Route path="/contact/" element={<ContactPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
