import React from 'react';
import Button from './Button';

function SubscriptionPlan({id, term, price, sublinePrice, info, promotion=' '}) {

    return (
        <>
            <div className='subscription-plan'>
                <div>
                    <div className='term'>{term}</div>
                    <div className='price'>{price}</div>
                    <div className='promotion'>{promotion}</div>
                    <div className='subline'>{sublinePrice}</div>
                </div> 
                <div>
                    <div className='info'>{info}</div>
                    <Button text='SUBSCRIBE NOW' link={`/secure/${id}/`} type='subscribe-button'/>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPlan;