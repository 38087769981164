import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const ContactPage = () => {
    const navigate = useNavigate()
    const [overlay, setOverlay] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (formData) => {
        const url = 'https://ed-backend.onrender.com/contact'
     
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: JSON.stringify({
                fname: 'FROM VIDEO SITE CONTACT FORM !!!!!!!!!!!!!!!!!',
                message: formData,
            })
        })
        setOverlay(true)
    }

    return (
        <>
            {overlay &&
                    <div className='subscribe-overlay' onClick={() => {setOverlay(false); navigate('/videos')}}>
                    <div className='dialog'>
                        <div className='text'>
                            Your message has been received!
                            We will get back to you as soon as possible.
                        </div>
                        <div className='subscribe-button close' onClick={() => setOverlay(false)}>CLOSE</div>
                    </div>
                </div>
            }
            <div className='contact-page'>
                <div className='text'>
                    Fill out this form to contact us<br /><br />
                </div>
                <form id="contact-form" onSubmit={handleSubmit(onSubmit)} method="POST">
                    <label>Name</label>
                    <input {...register('name')}></input>
                    <label>Email</label>
                    {/* <input {...register('email', {required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/})}/> */}
                    <input {...register('email', {required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/})}></input>
                    {errors && errors.email && errors.email.type === "required" && (
                    <p className="error-msg">Email is required.</p>
                    )}
                    {errors && errors.email && errors.email.type === "pattern" && (
                        <p className="error-msg">Email is not valid.</p>
                    )}
                    <label>Message</label>
                    <textarea {...register('message')}></textarea>
                    <button type='submit'>Send Message</button>
                </form>
            </div>
        </>

    );
};

export default ContactPage;