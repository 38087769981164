import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Video from './Video';
import Scene from './Scene';
import Button from './Button';
import _ from 'lodash'
import videos from '../assets/video/videos'
import { HiOutlineHeart } from "react-icons/hi";

export function Detail(props) {
    const [scene, setScene] = useState(0);
    let { id } = useParams();

    useEffect(() => {
        const scene = _.find(videos, v => v.id.toString() === id.toString())
        let relatedVideos = _.filter(videos, v => v.id.toString() !== id.toString())
        const related = []
        for (let i=0; i<4; i++) {
            const item = relatedVideos.splice(Math.floor(Math.random()*relatedVideos.length), 1)[0]
            related.push(item)
        }
        scene.related = related
        setScene(scene)
      }, [id]);

    

    return (
        <>
            scene && {
                <div className='detail-page'>
                    <div className='left'>
                        <div className='top'>
                            <div>
                                <div className='title'>{scene.title}</div>
                                <div className='actor'>{scene.actor}</div>
                            </div>
                            <div className='favourite'>
                                <div className='favourite-count'>{scene.favourited}</div>
                                <HiOutlineHeart className='favourite-icon'/>
                            </div>
                        </div>
                        <div className='video'>
                            <Video id={scene.id} stream={scene.stream} poster={scene.poster} onPause={'subscribe'}/>
                        </div>
                        <div className='scene-description'>{scene.description}</div>
                        <div className='subscribe-banner' style={{ backgroundImage:`linear-gradient(
                            rgba(255, 255, 255, 0.7), 
                            rgba(255, 255, 255, 0.7)
                            ), url(${scene.poster})` }}>
                            <div className='title'>Become a full memeber</div>
                            <div className='text'>To view all videos and get full access please sign up now.</div>
                            <Button text='subscribe' link='/subscribe' type='subscribe-button'/>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='title'>Related Scenes</div>
                        <div className='related'>
                            {scene.related && scene.related.map(video => <Scene
                                key={video.id} 
                                id={video.id}
                                title={video.title}
                                poster={video.poster}
                                actor={video.actor}
                                favourited={video.favourited}/>)}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}