import './App.css';
import Button from './components/Button';
import { Outlet, Link, useNavigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Logo from './components/Logo';
import Footer from './components/Footer';
import { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { LuLock } from "react-icons/lu";

const Layout = () => {
  const navigate = useNavigate();
  const [menuClosed, setMenuClosed] = useState(true)

  const toggleMenu = () => {
    setMenuClosed(!menuClosed)
  }

  return (
    <>
      <div className='banner'>
        <div className='text'>
          SUBSCRIBE and get access to 200+ videos and extras in 4K and FULL HD quality!
        </div>
      </div>
      <nav className='navbar'>
        <ul className='navbar-left'>
          <li>
            <div className='menu-container'>
              <Logo onClick={() => navigate('/')}/>
              <GiHamburgerMenu onClick={toggleMenu} className='hide-large burger-menu'/>
            </div>
          </li>
          <li className={menuClosed ? 'menu-closed' : ''} onClick={() => setMenuClosed(true)}>
            <Link to="/videos">VIDEOS</Link>
          </li>
        </ul>
        <ul className={menuClosed ? 'menu-closed navbar-right' : 'navbar-right'}>
          <li onClick={() => setMenuClosed(true)}>
            <Button text='Join Now' type='subscribe-button' link='/subscribe' icon={<LuLock />}/>
          </li>
          <li onClick={() => setMenuClosed(true)}>
            <CgProfile className='profileIcon'/>
            <Link to="/login">LOG IN</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#5A4063" }}
        buttonStyle={{ backgroundColor:"#F50072", color: "white", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <Footer />
    </>
  )
};

export default Layout;