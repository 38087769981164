import React from 'react';
import { useNavigate } from 'react-router-dom';

const Button = (props) => {
    let {text, type, link, icon} = props

    const navigate = useNavigate();
  
    const navigateToPage = () => {
        navigate(`${link}`)
    }

    return (
        <div
            className={type}
            onClick={navigateToPage}>
            {icon && <span className="btn-icon">{icon}</span>}
            {text}
        </div>
    );
};

export default Button;