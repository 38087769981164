import React from 'react';
import SubscriptionPlan from './SubscriptionPlan';

function SubscribePage(props) {
    return (
        <div className='subscribe-page'>
            <div className='title'>CHOOSE YOUR MEMBERSHIP </div>
            <div className='plans'>
                <SubscriptionPlan id='847369' term='Monthly' price='23 $ / month' sublinePrice='first month 49 dollar' info=''/>
                <SubscriptionPlan id='592243' term='Annual' price='165 $ / year' sublinePrice='' info='' promotion='Save 40% vs. monthly'/>
            </div>
        </div>
    );
}

export default SubscribePage;