import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate()

    return (
        <div className='footer'>
            <div className='contact'>
                To contact us? Fill out the contact form here:
                <div className='subscribe-button' onClick={() => navigate('/contact')}>Contact</div>
            </div>
            <div>
                NOTICE: All Models Appearing on this Website are 18 Years or Older
            </div>
            <div className='links'>
                <a href='/terms.html' target='_blank'>Terms Of Service</a>
                <a href='/privacy.fiscal.html' target='_blank'>Privacy Policy</a>
            </div>
            <div className='text'>
                All girls appearing on this website are 18 years or older. Click <a href='/sumom.html' target='_blank'>here</a> for 18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement. By entering this site you swear that you are of legal age in your area to view adult material and that you wish to view such material.
            </div>
        </div>
    );
};

export default Footer;