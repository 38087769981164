import React from 'react';
import VideoGrid from './VideoGrid';
import { useState } from 'react';
import LandingPage from './LandingPage'
import Button from './Button';

const VideoPage = (props) => {
    const [sortBy, setSortBy] = useState(['date'])
    const [page, setPage] = useState(1)
    const [overlay, setOverlay] = useState(false)

    return (
        <>
            {props.landingPage && <LandingPage />}
            {overlay &&
            <div className='subscribe-overlay' onClick={() => setOverlay(false)}>
                <div className='dialog'>
                    <div className='text'>
                        SUBSCRIBE NOW and get access to 200+ videos and extras in 4K and FULL HD quality!
                    </div>
                    <Button text='SUBSCRIBE' type='subscribe-button' link={'/subscribe'}/>
                    <div className='subscribe-button close' onClick={() => setOverlay(false)}>CLOSE</div>
                </div>
            </div>}
            <div className='video-page'>
                <div className='videos-heading'>
                    <div className='sort-button' onClick={() => {setSortBy('date'); setPage(1)}}>NEWEST</div>
                    <div className='sort-button alternative' onClick={() => {setSortBy('favourited'); setPage(1)}}>MOST POPULAR</div>
                </div>
                <div className='videos'>
                    <VideoGrid page={page} sortBy={sortBy}/>
                </div>
                <div className='page-buttons'>
                    <div className='page-selector' aria-disabled={page<=1} onClick={() => {setPage(page-1); ; window.scrollTo(0,0)}}>previous page</div>
                    <div className={page===1 ? 'page-selector selected' : 'page-selector'} aria-disabled={page === 1} onClick={() => {setPage(1); window.scrollTo(0,0)}}>1</div>
                    <div className={page===2 ? 'page-selector selected' : 'page-selector'} aria-disabled={page === 2} onClick={() => {setPage(2); window.scrollTo(0,0)}}>2</div>
                    <div className='page-selector' onClick={() => setOverlay(true)}>3</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>4</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>5</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>6</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>7</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>8</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>9</div>
                    <div className='page-selector hide-small' onClick={() => setOverlay(true)}>10</div>
                    <div className='page-selector' onClick={() => {
                        window.scrollTo(0,0)
                        page >= 2 ? setOverlay(true) : setPage(page+1)
                    }}>next page</div>
                </div>
            </div>
        </>
    );
};

export default VideoPage;