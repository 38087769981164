import moment from 'moment';
// import _ from 'lodash'

const videos = [
  {
    id: 2017114,
    title: "Spanish hottness",
    actor: "Rebecca",
    date: "2017-12-3",
    suffix: "scene1",
    stream: "/videos/scene1.mov",
    preview: "/videos/scene1-preview.mov",
    poster: "/images/scene1.jpg",
    description: "Spanish girls are hot we know that. And Rebecca is no exception. A bit shy at first but then she lets loose of her inner porn star.",
    favourited: 4.6
  },
  {
    id: 2017080,
    title: "Just a blowjob u say?",
    actor: "Lilly",
    date: "2017-10-24",
    stream: "/videos/scene2.mov",
    preview: "/videos/scene2-preview.mov",
    poster: "/images/scene2.jpg",
    description: "If all girls could give blowjobs like Lilly, the world would be a better place. A true deepthroat queen!",
    favourited: 4.2
  },
  {
    id: 2017067,
    title: "Cute and shy",
    actor: "Sandy",
    date: "2017-08-26",
    stream: "/videos/scene3.mov",
    preview: "/videos/scene3-preview.mov",
    poster: "/images/scene3.jpg",
    description: "They don't come cuter and shier than Sandy. But don't be deceived, she has a lot of bedroom skills!",
    favourited: 4.9
  },
  {
    id: 2017036,
    title: "What is better than 1 guy?",
    actor: "Sofia",
    date: "2017-04-14",
    stream: "/videos/scene4.mov",
    preview: "/videos/scene4-preview.mov",
    poster: "/images/scene4.jpg",
    description: "What is better than 1? 2 guys of course!",
    favourited: 3.9
  },
  {
    id: 2017034,
    title: "So young",
    actor: "Emika",
    date: "2017-04-13",
    stream: "/videos/scene5.mov",
    preview: "/videos/scene5-preview.mov",
    poster: "/images/scene5.jpg",
    description: "OMG! Just 19 years old and so increadibly skilled. Watch how she magiclly makes his dick dissapear in her throat. That dezerves a cream pie.",
    favourited: 4.3
  },
  {
    id: 2018029,
    title: "More spanish hottness",
    actor: "Maria",
    date: "2018-08-03",
    stream: "/videos/scene6.mov",
    preview: "/videos/scene6-preview.mov",
    poster: "/images/scene6.jpg",
    description: "Next stop in Spain is Maria. Don't let her name mislead u. She is no saint. Sucks like a pro and gets a huge load on her face as a thank u.",
    favourited: 4.7
  },
  {
    id: 2018059,
    title: "What's cookin?",
    actor: "Emma",
    date: "2018-12-21",
    stream: "/videos/scene7.mov",
    preview: "/videos/scene7-preview.mov",
    poster: "/images/scene7.jpg",
    description: "What's cookin Emma? Do you have some time for a quick fuck? Of course you do. I hope you are still hungry after the nice load in your mouth.",
    favourited: 4.5
  },
  {
    id: 2019029,
    title: "Uniforms are hot!",
    actor: "Charlotte",
    date: "2019-02-19",
    stream: "/videos/scene8.mov",
    preview: "/videos/scene8-preview.mov",
    poster: "/images/scene8.jpg",
    description: "Hi Charlotte, what a sexy uniform u have there. I will try my very best not not rip it from your body. And my god what a body.",
    favourited: 4.8
  },
  {
    id: 2019044,
    title: "They grow up so fast",
    actor: "Nora",
    date: "2019-06-22",
    stream: "/videos/scene9.mov",
    preview: "/videos/scene9-preview.mov",
    poster: "/images/scene9.jpg",
    description: "Girls these days, they grow up so fast! Just 18 and already doing porn. Well you don't hear us complaining of course.",
    favourited: 4
  },
  {
    id: 2019056,
    title: "Gangbang please",
    actor: "Lucia",
    date: "2019-07-14",
    stream: "/videos/scene10.mov",
    preview: "/videos/scene10-preview.mov",
    poster: "/images/scene10.jpg",
    description: "Lucia is back! She asked us if we could give her a gangbang. And of course we did not let her down and provided her with some nice hard dicks.",
    favourited: 4.9
  },
  {
    id: 2019073,
    title: "Mexican duo",
    actor: "Elena, Anna",
    date: "2019-10-04",
    stream: "/videos/scene11.mov",
    preview: "/videos/scene11-preview.mov",
    poster: "/images/scene11.jpg",
    description: "Can you handle 2 Mexican chica's? Wel yes, but barely. My god, are all Mexican girls so good at sucking dick.",
    favourited: 4.7
  },
  {
    id: 2020002,
    title: "Just gorgeous",
    actor: "Olivia",
    date: "2020-01-10",
    stream: "/videos/scene12.mov",
    preview: "/videos/scene12-preview.mov",
    poster: "/images/scene12.jpg",
    description: "The dictionary should have an image of Olivia next to gorgeos. There is no better meaning for that word.",
    favourited: 4.6
  },
  {
    id: 2020053,
    title: "So cute",
    actor: "Elizabeth",
    date: "2020-05-15",
    stream: "/videos/scene13.mov",
    preview: "/videos/scene13-preview.mov",
    poster: "/images/scene13.jpg",
    description: "What a wonderfull petite ass. That is so cute. Does she still look cute with a huge dick in her mouth. Yes she does!",
    favourited: 4.9
  },
  {
    id: 2020061,
    title: "Titties, titties and titties",
    actor: "Isla",
    date: "2020-10-05",
    stream: "/videos/scene14.mov",
    preview: "/videos/scene14-preview.mov",
    poster: "/images/scene14.jpg",
    description: "Are these titties perfect or what? And that is not all. She sucks and fucks like a pro. Isla is just perfect.",
    favourited: 4
  },
  {
    id: 2020067,
    title: "Delicious candy",
    actor: "Ivy",
    date: "2020-10-31",
    stream: "/videos/scene15.mov",
    preview: "/videos/scene15-preview.mov",
    poster: "/images/scene15.jpg",
    description: "Lets dress you up like a candy bar. It makes you look even more yummy. If that is even possible.",
    favourited: 3.9
  },
  {
    id: 2021014,
    title: "She has got the look",
    actor: "Maya",
    date: "2021-01-30",
    stream: "/videos/scene16.mov",
    preview: "/videos/scene16-preview.mov",
    poster: "/images/scene16.jpg",
    description: "She has got the look. You know that look that makes you weak in seconds. Well not everything of course.",
    favourited: 4.9
  },
  {
    id: 2021032,
    title: "Smile",
    actor: "Alice",
    date: "2021-04-11",
    stream: "/videos/scene17.mov",
    preview: "/videos/scene17-preview.mov",
    poster: "/images/scene17.jpg",
    description: "Sucking, fucking, everything Alice does, she does with a smile.",
    favourited: 4.7
  },
  {
    id: 2021041,
    title: "I love cream pie in the morning",
    actor: "Josephine",
    date: "2021-06-25",
    stream: "/videos/scene18.mov",
    preview: "/videos/scene18-preview.mov",
    poster: "/images/scene18.jpg",
    description: "Sucking dick in the morning is not a chore for Josephine. She knows at the end of the ride she will get her well deserved cream pie.",
    favourited: 4.3
  },
  {
    id: 2021050,
    title: "Lingerie fun",
    actor: "Madeline",
    date: "2021-09-02",
    stream: "/videos/scene19.mov",
    preview: "/videos/scene19-preview.mov",
    poster: "/images/scene19.jpg",
    description: "We don't have enough lingerie videos at HOTEL-POV. I agree. Madeline did her best selecting a nice lingerie set for u guys.",
    favourited: 4.8
  },
  {
    id: 2022001,
    title: "Looking good",
    actor: "Gabriella",
    date: "2022-01-04",
    stream: "/videos/scene20.mov",
    preview: "/videos/scene20-preview.mov",
    poster: "/images/scene20.jpg",
    description: "Taking pictures of your body are you? To be honest. I would do the same thing if I had a body like that. My god ...",
    favourited: 4.5
  },
  {
    id: 2022032,
    title: "Choke me please",
    actor: "Bella",
    date: "2022-03-15",
    stream: "/videos/scene21.mov",
    preview: "/videos/scene21-preview.mov",
    poster: "/images/scene21.jpg",
    description: "Well Bella, you don't need to ask that twice. If choke you ask, choke you get. And I can see you love it.",
    favourited: 4.8
  },
  {
    id: 2021057,
    title: "Southern hottness",
    actor: "Aline",
    date: "2021-12-10",
    stream: "/videos/scene22.mov",
    preview: "/videos/scene22-preview.mov",
    poster: "/images/scene22.jpg",
    description: "Hot, hotter, hottest. No kidding. There is no stopping this girl.",
    favourited: 3.9
  },
  {
    id: 2022040,
    title: "Like a pro",
    actor: "Sadie",
    date: "2021-03-11",
    stream: "/videos/scene23.mov",
    preview: "/videos/scene23-preview.mov",
    poster: "/images/scene23.jpg",
    description: "Suck, fuck. Everything like a pro. Long and hard deepthroat is just one of the things that Sadie excells at.",
    favourited: 4.5
  },
  {
    id: 2022047,
    title: "Dress up baby",
    actor: "Abigail",
    date: "2020-07-23",
    stream: "/videos/scene24.mov",
    preview: "/videos/scene24-preview.mov",
    poster: "/images/scene24.jpg",
    description: "Time for another outfit. Can you check if it fits? Now can you check if my dick fits?",
    favourited: 4.4
  }
]

export default videos.map(v => {
  v.date = moment(v.date)
  // v.stream = _.replace(v.stream, '/videos/', 'https://video-service-f906.onrender.com/videos/')
  //v.preview = _.replace(v.preview, '/videos/', 'https://video-service-f906.onrender.com/videos/')
  return v
})
