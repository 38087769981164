import Scene from './Scene';
import React, { Component } from 'react';
import _ from 'lodash';
import videos from '../assets/video/videos'

class VideoGrid extends Component {

    constructor (props) {
        super(props)
        this.state = {
            videos: null
        }
      }
    
    render() {
        const { sortBy, page } = this.props
        
        if(!videos) return
        let videosForPage = videos && _.sortBy(videos, [v => -v[sortBy]])
        videosForPage = videosForPage && videosForPage.slice(0+(page-1)*12, 12+(page-1)*12)

        return (
            <div className='video-grid'>
                {videosForPage && videosForPage.map(video => <Scene
                    key={video.id} 
                    id={video.id}
                    title={video.title}
                    stream={video.preview}
                    poster={video.poster}
                    actor={video.actor}
                    favourited={video.favourited} />)}
            </div>
        );
    }
}

export default VideoGrid;