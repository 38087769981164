import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';

function LandingPage(props) {
    const navigate = useNavigate();

    const navigateToPage = (link) => {
        navigate(`${link}`)
    }

    useEffect(() => {
        const url = 'https://ed-backend.onrender.com/contact'
     
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: JSON.stringify({
                fname: 'FROM VIDEO SITE LANDING PAGE !!!!!!!!!!!!!!!!!',
            })
        })
        }, []);

    return (
        <div className='landing-page'>
            <div className='dialog'>
                <div className='message'>
                <Logo />
                    Welcome!<br/><br/>

                    WARNING - 18+ ADULTS ONLY<br/><br/>

                    You must be at least 18 years old to enter.
                </div>
                <div className='buttons'>
                    <button onClick={() => navigateToPage('/videos')}>Enter</button>
                    <button onClick={() => window.location.replace('https://google.com')} >Leave</button>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;