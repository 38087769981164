import React from 'react';

function LoginPage(props) {
    return (
        <div className='login-page'>
            <div className='account'>
                <div className='title'>Log In</div>
                Email address:
                <input className='email' placeholder='e-mail address'></input>
                Password:
                <input className='password' placeholder='' type='password'></input>
                <div
                    className='subscribe-button'>
                    LOG IN
                </div>
            </div>
        </div>
    );
}

export default LoginPage;