import React from 'react';
import Video from './Video';
import {useNavigate} from 'react-router-dom';
import { HiOutlineHeart } from "react-icons/hi";

const Scene = props => {
    const { id, title, stream, actor, poster, favourited = 256 } = props

    const navigate = useNavigate();
  
    const toDetailPage = () => {
        navigate(`/detail/${id}`)
        window.scrollTo(0,0);
    }
    return (
        <div className='scene' >
            <Video id={id} stream={stream} poster={poster} isPreview={true} onClick={toDetailPage}/>
            <div className='scene-info' onClick={toDetailPage}>
                <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='actor'>{actor}</div>
                </div>
                <div className='favourite'>
                    <div className='favourite-count'>{favourited}</div>
                    <HiOutlineHeart />
                </div>
            </div>
        </div>
    );
};

export default Scene;